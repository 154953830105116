import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getRefundRulesList: createCancelTokenHandler('getRefundRulesList')
}

export function getRefundRulesList (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/club/refund-rules${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getRefundRulesList'].handleRequestCancellation().token
  })
}


export function getRefund (id) {
  return axios({
    url: `v1/admin/club/refund-rules/${id}`,
    method: 'get'
  })
}

export function editRefund (id, payment) {
  return axios.patch(`v1/admin/club/refund-rules/${id}`, payment)
}
